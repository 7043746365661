/* RULES */

header {
    z-index: 11;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
}

.header-show {
    position: relative;
    top: 0;
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
    transition: var(--anim-02);
}

.header-hide {
    position: relative;
    top: -72px;
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
    transition: var(--anim-05);
}

.header-shape {
    max-width: 1024px;
    height: 0;
    border-top: 72px solid var(--bg-dark);
    border-left: 41px solid transparent;
    border-right: 41px solid transparent;
    margin-left: auto;
    margin-right: auto;
}

.header-left {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 64px;
}

.header-left-logo {
    float: left;
    height: 48px;
    margin-top: 12px;
    margin-left: 12px;
    transition: var(--anim-02);
}

.header-left-logo:hover, .header-left-logo:focus {
    opacity: 0.5;
}

.header-left-name {
    float: left;
    width: 160px;
    margin: 18px 0 0 18px;
}

/* RIGHT */

.header-right {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 22px;
}

.header-right-btn, .header-right-btn-active {
    float: left;
    width: 200px;
    height: 48px;
    transform: skew(-30deg);
    margin-top: 12px;
    margin-right: 12px;
    color: var(--txt-secondary);
    text-align: center;
    cursor: pointer;
    transition: var(--anim-02);
}

.header-right-btn:hover, .header-right-btn:focus {
    background: var(--txt-secondary);
    color: var(--txt-contrast);
}

.header-right-btn:active, .header-right-btn-active {
    background: var(--primary-main);
    color: var(--txt-contrast);
}

.header-right-btn h4, .header-right-btn-active h4 {
    transform: skew(30deg);
    line-height: 0;
    margin-top: 25px;
    font-weight: 700;
    filter: drop-shadow(0px 0px 0px var(--act-invisible));
}

/* TOUCH */

.header-touch {
    display: none;
    position: absolute;
    top: 0;
    width: 100%;
    padding: 12px 48px;
    margin-left: auto;
    margin-right: auto;
}

.header-touch-icon {
    vertical-align: top;
    width: 33.333%;
    height: 48px;
    opacity: 0.4;
    cursor: pointer;
    transition: var(--anim-02);
}

.header-touch-icon:hover, .header-touch-icon:focus, .header-touch-icon-active {
    opacity: 1;
}

/* MEDIA QUERIES */

@media screen and (max-width: 1600px) {

}
@media screen and (max-width: 1280px) {
    .header-show, .header-hide, .header-shape {
        max-width: 768px;
    }
    .header-left-logo {
        margin-left: 8px;
    }
    .header-left-name {
        display: none;
    }
    .header-right-btn, .header-right-btn-active {
        width: 180px;
    }
}
@media screen and (max-width: 1000px) {

}
@media screen and (max-width: 800px) {
    .header-show, .header-hide, .header-shape {
        max-width: 400px;
    }
    .header-left, .header-right {
        display: none;
    }
    .header-touch {
        display: initial;
    }
}
@media screen and (max-width: 600px) {

}
@media screen and (max-width: 480px) {
    .header-show, .header-hide, .header-shape {
        max-width: 320px;
    }
}
@media screen and (max-width: 360px) {
    .header-show, .header-hide, .header-shape {
        max-width: 300px;
    }
    .header-shape {
        border-top: 64px solid var(--bg-dark);
        border-left: 37px solid transparent;
        border-right: 37px solid transparent;
    }
    .header-touch {
        padding: 8px 48px;
    }
}