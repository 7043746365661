/* RULES */

/* REEL */

.work-reel {
    position: relative;
    width: 100%;
    padding: 12px 0;
    background: var(--bg-main);
}

.work-reel-box {
    position: relative;
    width: 960px;
    margin-left: auto;
    margin-right: auto;
}

.work-reel-movie {
    display: block;
    width: 100%;
    outline: none;
}

/* WEB */

.work-web {
    position: relative;
    width: 100%;
    height: 100%;
}

.work-web-box {
    width: fit-content;
    max-width: 1440px;
    height: fit-content;
    padding: calc(12px * var(--scale));
    margin-left: auto;
    margin-right: auto;
    background: var(--bg-main);
}

.work-web-item {
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    width: calc(330px * var(--scale));
    height: calc(330px * var(--scale));
    margin: calc(12px * var(--scale));
    background-repeat: no-repeat;
    background-size: cover;
    transition: var(--anim-02);
}

.work-web-item:hover, .work-web-item:focus {
    cursor: pointer;
    transform: scale(1.075);
}

.work-web-item-overlay {
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 12px;
    background-color: #000000cc;
    opacity: 0;
    transition: var(--anim-02);
}

.work-web-item-overlay:hover, .work-web-item-overlay:focus {
    opacity: 1;
}

.work-web-item-overlay:active {
    background-color: var(--primary-light);
}

.work-web-item-head {
    z-index: 2;
    line-height: 1;
    font-size: calc(32px * var(--scale));
    font-weight: 500;
    margin: calc(32px * var(--scale)) 0;
    opacity: 1;
}

.work-web-item-body {
    z-index: 2;
    line-height: 1.333;
    font-size: calc(21px * var(--scale));
    font-weight: 300;
    padding: 0 calc(28px * var(--scale));
    opacity: 1;
}

/* MEDIA QUERIES */

@media screen and (max-width: 1600px) {
    .work-web-box {
        max-width: calc(732px * var(--scale));
    }
}
@media screen and (max-width: 1280px) {

}
@media screen and (max-width: 1000px) {
    .work-reel-box {
        width: 100%;
    }
}
@media screen and (max-width: 800px) {
    
}
@media screen and (max-width: 600px) {
    .work-web-box {
        max-width: calc(378px * var(--scale));
    }
}
@media screen and (max-width: 480px) {

}
@media screen and (max-width: 360px) {

}